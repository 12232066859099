import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function WeatherPage({ data, pageContext }) {
  const { parent, children, siblings } = pageContext;
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout
      fixedHeader={frontmatter.image ? true : false}
      parent={parent}
      title={frontmatter.title}
    >
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={frontmatter.title}
      />

      <PageTemplate
        html={html}
        parent={parent}
        childPages={children}
        siblings={siblings}
      />
    </Layout>
  );
}

export const PageTemplate = ({ html }) => {
  return (
    <>
      {html && (
        <section className="">
          <div className="iframe-container">
            <article
              className=" prose prose-xl"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </section>
      )}
    </>
  );
};

PageTemplate.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  html: PropTypes.string,
  parent: PropTypes.object,
  childPages: PropTypes.array,
  siblings: PropTypes.array,
};

WeatherPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        intro
        widgets {
          title
          text
          link
          image
        }
      }
      html
    }
  }
`;

export default WeatherPage;
